// InicialScreen.js
import React, { useEffect, useState } from "react";
import axios from "axios"; // Importando o Axios
import * as signalR from "@microsoft/signalr";
import "../InitialScreen.css"; // Importando o CSS específico
import logo from "./logo.png";
import { useParams, useNavigate } from "react-router-dom";

const InicialScreen = () => {
  const navigate = useNavigate();


  const [messages, setMessages] = useState([]);
  const [mensagemRevelacao, setMensagemRevelacao] = useState(null);
  const [sexoDoBebe, setSexoDoBebe] = useState(null);
  const [finalizado, setFinalizado] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [count, setCount] = useState(0);

  useEffect(() => {
    
  }, []);
  
  const [loadingPercentage, setLoadingPercentage] = useState(0);
  const { id } = useParams();

  const idUtilizar = id ?? "563FCD17-80AC-48FA-9BF4-BC80FBFDD482";

  const getData = async () => {
    const response = await axios.get("http://www.webprova.com.br/api/Revelacao/Dados/" + idUtilizar);
    setSexoDoBebe(response?.data?.sexo);
    setMensagemRevelacao(response?.data?.mensagemRevelacao);
    setFinalizado(response?.data?.eventoFinalizado);
    setImageUrl(response?.data?.imagesrc);
    response?.data?.mensagens?.map((item) => calculaPercentual(item.nome, item.sexo, true));
    setMessages(response?.data?.mensagens ?? []);
  };

  const calculaPercentual =(qtdMessagesQueContam) => {
    if (finalizado) {
      setLoadingPercentage(100);
    } else {
      const newPercentage = Math.min(qtdMessagesQueContam * 3, 100);
      setLoadingPercentage(newPercentage);
    }
  };

  useEffect(() => {
    getData();
    const connection = new signalR.HubConnectionBuilder()
      .withUrl("http://www.webprova.com.br/myhub")
      .configureLogging(signalR.LogLevel.Information)
      .build();

    connection.on("ReceiveMessage", (nome, sexo, contaNoPercentual) => {
      const message = { nome, sexo, contaNoPercentual };
      setMessages(prevMessages => [...prevMessages, message]);
    });

    connection.on("FinalizarEvento", (mensagemRevelacao, sexoDoBebe, imagesrc) => {
      setLoadingPercentage(100);
      setSexoDoBebe(sexoDoBebe);
      setMensagemRevelacao(mensagemRevelacao);
      setImageUrl(imagesrc);
    });

    connection.start().catch(err => console.error("Connection error: ", err));

    return () => {
      connection.stop();
    };
  }, []);


  useEffect(() => {
    if (messages) {
      const qtdMessagesQueContam = messages.filter((x) => x.contaNoPercentual === true).length ?? 0;

      if (finalizado) {
        setLoadingPercentage(100);
      } else {
        const newPercentage = Math.min(qtdMessagesQueContam * 3, 100);
        setLoadingPercentage(newPercentage);
      }
    }
  }, [messages, finalizado]);

  // const connection = new signalR.HubConnectionBuilder().withUrl("hhttp://www.webprova.com.br/myhub").configureLogging(signalR.LogLevel.Information).build();
  //   connection?.on("ReceiveMessage", (nome, sexo, contaNoPercentual) => {
  //     const message = { nome, sexo, contaNoPercentual };
  //     setMessages((prevMessages) => [...prevMessages, message]);
  //   });

  //   connection?.on("FinalizarEvento", (mensagemRevelacao, sexoDoBebe, imagesrc) => {
  //     setLoadingPercentage(100);
  //     setSexoDoBebe(sexoDoBebe);
  //     setMensagemRevelacao(mensagemRevelacao);
  //     setImageUrl(imagesrc);

  //   });

  //   connection?.start().catch((err) => console.error("Connection error: ", err));
  //   return () => {
  //     connection?.stop();
  //   };

  // Filtrando mensagens por sexo
  const meninoMessages = messages.filter((msg) => msg.sexo === "Menino");
  const meninaMessages = messages.filter((msg) => msg.sexo === "Menina");

  return (
    <div className="App">
      <div className="App-header">
        <div className="message-list menino column column-1">
          <h2 className="message-list-title">Menino</h2>
          {meninoMessages.map((msg, index) => (
            <div key={index} className="message-item">
              <p>{msg.nome}</p>
            </div>
          ))}
        </div>

        <div className="image-container column column-2">
          <div className="brand">
            <img src={logo} alt="" />
          </div>
          <div style={{ position: "relative", width: "auto", height: "415px", overflow: "hidden", borderRadius: "20px" }}>
            {loadingPercentage < 100 && (
              <div
                style={{
                  position: "absolute",
                  top: `${loadingPercentage}%`,
                  left: 0,
                  width: "100%",
                  height: `${100 - loadingPercentage}%`,
                  backgroundColor: "black",
                  zIndex: 1,
                }}
              />
            )}

            {loadingPercentage === 100 && (
              <div
              className="message-reveal"
                style={{
                  position: "absolute",
                  top: `${90}%`,
                  left: 0,
                  width: "100%",
                  height: `10%`,
                  backgroundColor: sexoDoBebe === "Menina" ? "#ed62b3" : "#39b5fd",
                  zIndex: 1,
                }}
              >
                <p>{mensagemRevelacao}</p>
                </div>
            )}
            <img src={imageUrl} alt="" style={{ width: "100%", height: "100%", display: "block", zIndex: 0 }} />
            {loadingPercentage < 100 && (
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  color: "white",
                  fontSize: "24px",
                  zIndex: 2,
                }}
              >
                {`${loadingPercentage}%`}
              </div>
            )}
          </div>
        </div>
        <div className="message-list menina column column-3">
          <h2 className="message-list-title">Menina</h2>
          <div className="message-list-content">
            {meninaMessages.map((msg, index) => (
              <div key={index} className="message-item">
                <p>{msg.nome}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InicialScreen;
