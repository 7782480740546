import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import InicialScreen from "./Components/InicialScreen"; // Componente para a tela inicial
import MensagemScreen from "./Components/MensagemScreen"; // Componente para a tela de mensagem

const App = () => {
  return (
    <BrowserRouter basename="/">
      <Routes>
        <Route path="/" element={<InicialScreen />} /> {/* Rota para a tela inicial */}
        <Route path="/:id" element={<InicialScreen />} /> {/* Rota para a tela inicial */}
        <Route path="/mensagem/:id" element={<MensagemScreen />} /> {/* Rota para a tela de mensagem */}
      </Routes>
    </BrowserRouter>
  );
};

export default App;
