// MensagemScreen.js
import React, { useState } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import axios from 'axios'; // Importando o Axios
import '../Mensagem.css'; // Importando o CSS específico
import logo from "./logo.png";

const MensagemScreen = (props) => {
  const navigate = useNavigate();

  const [nome, setNome] = useState('');
  const [mensagem, setMensagem] = useState('');
  const [sexo, setSexo] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const { id } = useParams();

  const getData = async () => {
    const response = await axios.get("http://www.webprova.com.br/api/Revelacao/Identificacao/" + id);
    setSexo(response.data.sexo);
  }

  getData();


  const handleMensagemChange = (event) => {
    setMensagem(event.target.value);
  };

  const handleNomeChange = (event) => {
    setNome(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!nome || !sexo) {
      setErrorMessage('Por favor, preencha todos os campos.');
      return;
    }

    const postData = {
      id: id,
      nome: nome,
      sexo: sexo,
      comentario: mensagem
    };

    try {
      await axios.post('http://www.webprova.com.br/api/Revelacao', postData);

      // Limpa os campos após o envio bem-sucedido
      setNome('');
      setSexo('');
      setSuccessMessage('Mensagem enviada com sucesso!');
      setErrorMessage('');
    } catch (error) {
      setSuccessMessage('');
      setErrorMessage('Erro ao enviar mensagem. Por favor, tente novamente.');
    }
  };

  const sexoClassName = sexo === "Menina" ? "container menina" : "container menino";
  const apuracaoClassName = sexo === "Menina" ? "button button-apuracao-menina" : "button button-apuracao-menino";

  const handleApuracao = () => {
    return navigate("/");
  }

  return  (
    <div className="all">
      <div className="brand message-home"><img src={logo} alt=""/></div>
      {id && sexo && <div className={sexoClassName}>
        <h2>Chá Revelação - Jabbur Elias</h2>
        {errorMessage && <p className="errorMessage">{errorMessage}</p>}
        {successMessage && <p className="successMessage">{successMessage}</p>}
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="nome" className="label">Informe o seu Nome:</label>
            <input type="text" id="nome" className="input" value={nome} onChange={handleNomeChange} required />
          </div>

          <div className="form-group">
            <label htmlFor="mensagem" className="label">Caso queira, deixe uma mensagem:</label>
            <input type="text" id="mensagem" className="input" value={mensagem} onChange={handleMensagemChange} />
          </div>

          <div className='button-container'>
            <button type="button" className={apuracaoClassName} onClick={handleApuracao}>Apuração</button>
            <button type="submit" className="button">Enviar</button>
          </div>
        </form>
      </div>}
    </div>
  );
};

export default MensagemScreen;
